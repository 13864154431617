import React, { useEffect, useState, useRef, Suspense } from 'react';

import { useRecoilState } from 'recoil';
import { productAtom, loadingAtom, exporAtom } from '../core/services/config/atoms';

import { v4 as uuidv4 } from 'uuid';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import { saveAs } from 'file-saver';
import Tippy from '@tippyjs/react';
import { Canvas } from '@react-three/fiber';
import { View, OrbitControls, GizmoHelper, GizmoViewcube, Environment, ContactShadows, PerspectiveCamera } from '@react-three/drei';
import Pdf from "react-to-pdf";

import animLoading from '../assets/anim/anim-mainLoader.json';
import icTransperent from '../assets/images/ic_transperent.png';

import { useControls } from 'leva';

import Product0 from './products/Product0';
import Product1 from './products/Product1';
import Product2 from './products/Product2';
import Product3 from './products/Product3';
import Product4 from './products/Product4';
import Product5 from './products/Product5';
import Product6 from './products/Product6';
import Product7 from './products/Product7';
import Product8 from './products/Product8';
import Product9 from './products/Product9';
import Product10 from './products/Product10';
import Product11 from './products/Product11';
import Product12 from './products/Product12';
import Product13 from './products/Product13';
import Product14 from './products/Product14';
import Product15 from './products/Product15';
import Product16 from './products/Product16';
import Product17 from './products/Product17';
import Product18 from './products/Product18';
import Product19 from './products/Product19';
import Product20 from './products/Product20';
import Product21 from './products/Product21';
import Product22 from './products/Product22';
import Product23 from './products/Product23';

import 'tippy.js/dist/tippy.css';

const allowedExtensions = ["png"];

export default function PageFabConsole() {

    const [productData, setProductData] = useRecoilState(productAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [exportRef, setExportRef] = useRecoilState(exporAtom);

    const [{ globalLight, directionAIntencity, directionBIntencity, directionAPosition, directionBPosition, pointLightA, pointLightB, spotLightIntenticty, spotLightPosition }, set] = useControls(() => ({
        globalLight: 0,
        directionAIntencity: 0,
        directionBIntencity: 0,
        directionAPosition: [0.5, 0, 0.866],
        directionBPosition: [-0.5, 0, -0.866],
        pointLightA: 0,
        pointLightB: 0,
        spotLightIntenticty: 0,
        spotLightPosition: [1, 6, 1.5],
    }));

    const fileRef = useRef('');
    const pdfRef = React.createRef();

    const [textFile, setTextFile] = useState({ url: '', isAva: false });
    const [formData, setFormData] = useState({});
    const [opacityData, setOpacityData] = useState({});

    const [key, setkey] = useState(uuidv4());

    const [topUrl, setTopUrl] = useState('');
    const [bottomUrl, setBottomUrl] = useState('');
    const [frontUrl, setFrontUrl] = useState('');
    const [sideUrl, setSideUrl] = useState('');
    const [orthoUrl, setOrthoUrl] = useState('');

    const [isPdf, setIsPdf] = useState(false);
    const [isFile, setIsFile] = useState(false);

    const alert = useAlert();
    const navigate = useNavigate();

    const pdfOptions = {
        orientation: 'landscape',
        unit: "in",
        format: [14, 7]
    };

    const loadData = () => {
        if (productData.id === undefined) {
            setProductData({});
            navigate('/home', { replace: true });
        } else {
            productData.config.colors.map((color) => {
                switch (productData.id) {
                    case '4':
                        set({
                            globalLight: 2,
                            directionAIntencity: 0.6,
                            directionBIntencity: 0.6,
                            directionAPosition: [0.5, 0, 0.866],
                            directionBPosition: [-0.5, 0, -0.866],
                            pointLightA: 1,
                            pointLightB: 1,
                            spotLightIntenticty: 0,
                            spotLightPosition: [1, 6, 1.5],
                        });
                        break;
                    case '7':
                        set({
                            globalLight: 2,
                            directionAIntencity: 0.8,
                            directionBIntencity: 0.8,
                            directionAPosition: [0.5, 0, 0.866],
                            directionBPosition: [-0.5, 0, -0.866],
                            pointLightA: 0.6,
                            pointLightB: 1,
                            spotLightIntenticty: 0,
                            spotLightPosition: [1, 6, 1.5],
                        });
                        break;
                    case '8':
                    case '9':
                    case '10':
                    case '11':
                        set({
                            globalLight: 1.5,
                            directionAIntencity: 0.6,
                            directionBIntencity: 0.6,
                            directionAPosition: [0.5, 0, 0.866],
                            directionBPosition: [-0.5, 0, -0.866],
                            pointLightA: 0.6,
                            pointLightB: 1,
                            spotLightIntenticty: 0,
                            spotLightPosition: [1, 6, 1.5],
                        });
                        break;
                    default:
                        set({
                            globalLight: 1,
                            directionAIntencity: 0.4,
                            directionBIntencity: 0.4,
                            directionAPosition: [0.5, 0, 0.866],
                            directionBPosition: [-0.5, 0, -0.866],
                            pointLightA: 0.6,
                            pointLightB: 1,
                            spotLightIntenticty: 0,
                            spotLightPosition: [1, 6, 1.5],
                        });
                        break;
                }
                setFormData({ ...formData, [color.name]: color.value });
            });
        }
    }

    const onLoading = () => {
        setLoading(false);
    }

    const gotoBack = () => {
        if (exportRef) {
            setExportRef(false);
            setIsPdf(false);
        } else {
            setProductData({});
            navigate('/home', { replace: true });
        }
    }

    const donwloadTemplate = () => {
        saveAs(productData.config['stencil'], "Templates.zip");
    }

    const HandleFileChange = (e) => {
        if (formData['stickType'] == undefined || formData['stickType'] == '') {
            alert.show("Please select template type from dropdown!", { type: 'error' });
            fileRef.current.value = "";
        } else {
            if (e.target.files.length > 0) {
                let isError = false;

                for (let i = 0; i < e.target.files.length; i++) {
                    const inputFile = e.target.files[i];
                    const fileExtension = inputFile?.type.split("/")[1];
                    if (!allowedExtensions.includes(fileExtension)) {
                        alert.show("Only .png file format is allowed!", { type: 'error' });
                        isError = true;
                    }
                }

                if (isError) {
                    return;
                }

                setIsFile(true);

                getTextureFile(e.target.files[0]).then((res) => {
                    setkey(uuidv4());
                });
            }
        }
    }

    const removeFile = () => {
        setFormData({ ...formData, stickType: '' });
        setIsFile(false);
        setTextFile({ url: '', isAva: false });
        setkey(uuidv4());
        fileRef.current.value = "";
    }

    const exportPDF = () => {
        setExportRef(true);
    }

    const doneExport = (url) => {
        setTopUrl(url[0]);
        setBottomUrl(url[1]);
        setFrontUrl(url[2]);
        setSideUrl(url[3]);
        setOrthoUrl(url[4]);
        setIsPdf(true);
    }

    const doneDownload = () => {
        setExportRef(false);
        setIsPdf(false);
    }

    const createImageUrl = (buffer, type) => {
        const blob = new Blob([buffer], { type })
        const urlCreator = window.URL || window.webkitURL
        const imageUrl = urlCreator.createObjectURL(blob)
        return imageUrl
    }

    const getTextureFile = async (File) => {
        const file = File;
        const { type } = file;
        const buffer = await file.arrayBuffer();
        const imageUrl = createImageUrl(buffer, type);
        setTextFile({ url: imageUrl, isAva: true });
    }

    const OpacityChange = (name, value) => {
        setOpacityData({ ...opacityData, [name]: value });
    }

    const onFormChange = (e) => {
        var { value, name } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const isOpacity = () => {
        if (Object.keys(opacityData).length == 0) {
            return false;
        } else {
            for (let i = 0; i < productData.config.colors.length; i++) {
                if (opacityData[productData.config.colors[i].name]) {
                    return true;
                }
            }
        }
        return false;
    }

    useEffect(() => {
        loadData();
        return () => {
        }
    }, []);

    return (
        <>
            <div className='w-full h-screen' style={{ background: '#252525', background: 'linear-gradient(15deg, #17202A 0%, #252525 100%)' }}>
                {
                    loading && <div className='w-full h-screen bg-black flex items-center justify-center'>
                        <Lottie animationData={animLoading} className="intro-y w-96 mx-auto" loop={true} />
                    </div>
                }
                <div className='w-full h-screen relative'>
                    <div className='p-4 absolute text-white z-50 text-sm font-medium hover:text-base cursor-pointer' onClick={() => gotoBack()}><i className="las la-arrow-left"></i>&nbsp;&nbsp;Go Back</div>
                    <div className={`bg-gray-100 absolute z-50 shadow-md rounded p-2 ml-4 w-[200px] flex flex-col top-[60px] border ${loading ? 'hidden' : ''}`}>
                        {
                            !exportRef && <>
                                <p className='text-base text-black font-medium my-1'>Colors</p>
                                {
                                    productData.config.colors.map((color, index) => {
                                        return <>
                                            <div key={index}>
                                                <p className='text-xs text-black font-medium my-1'>{color.title}</p>
                                                <div className='flex items-center'>
                                                    <input type="color" name={color.name} value={formData[color.name]} onChange={onFormChange} />
                                                    <Tippy content="Solid Color" placement='top'>
                                                        <div className={`w-4 h-4 bg-green-600 ml-3 cursor-pointer ${opacityData[color.name] == undefined ? color.isTrans ? '' : 'border-2 border-black' : opacityData[color.name] ? '' : 'border-2 border-black'}`} onClick={() => {
                                                            OpacityChange(color.name, false);
                                                        }}></div>
                                                    </Tippy>
                                                    <Tippy content="No Fill" placement='top'>
                                                        <div className={`w-4 h-4 bg-green-600 ml-1 cursor-pointer ${opacityData[color.name] == undefined ? color.isTrans ? 'border-2 border-black' : '' : opacityData[color.name] ? 'border-2 border-black' : ''}`} onClick={() => {
                                                            OpacityChange(color.name, true);
                                                        }}>
                                                            <img src={icTransperent} alt="Icon transperent" />
                                                        </div>
                                                    </Tippy>
                                                </div>
                                            </div>
                                        </>
                                    })
                                }
                                <button className='text-sm h-8 text-white rounded font-medium my-2 bg-green-600' onClick={() => donwloadTemplate()}>
                                    <i className="las la-cloud-download-alt"></i>&nbsp;Download Templates
                                </button>
                                <p className='text-base text-black font-medium my-1'>Sticker</p>
                                <select className='text-xs text-black my-2 py-1 px-1 outline-none border border-black rounded' name='stickType' value={formData['stickType']} onChange={onFormChange}>
                                    <option value="">SELECT</option>
                                    {
                                        productData.config.variant.map((item, index) => {
                                            return <option value={item.models} key={index}>{item.title}</option>
                                        })
                                    }
                                </select>
                                {
                                    isFile && <div className='my-1 border cursor-pointer bg-red-600 border-dashed border-red-900 rounded flex items-center justify-center py-2' onClick={() => { removeFile() }}>
                                        <label className='text-xs cursor-pointer font-medium text-white'>Remove File</label>
                                    </div>
                                }
                                {
                                    !isFile && <div className='my-1 border cursor-pointer border-dashed border-blue-400 rounded flex items-center justify-center py-2'>
                                        <label htmlFor='stick' className='text-sm text-center cursor-pointer'>
                                            Template File<br />
                                            <span className='text-xs'>Only Supports .PNG file format</span>
                                        </label>
                                        <input type='file' ref={fileRef} name="stick" id="stick" required autoComplete='off' className='hidden cursor-pointer' onChange={HandleFileChange} />
                                    </div>
                                }

                                <button className='text-sm h-8 text-white rounded font-medium my-1 bg-blue-600' onClick={() => {
                                    exportPDF();
                                }}>GENERATE</button>
                            </>
                        }
                        {
                            exportRef && <Pdf targetRef={pdfRef} filename="div-blue.pdf" options={pdfOptions} onComplete={doneDownload}>
                                {({ toPdf }) => (
                                    <button className='text-sm h-8 text-white rounded font-medium my-1 bg-blue-600' onClick={toPdf}>EXPORT</button>
                                )}
                            </Pdf>
                        }
                    </div>
                    <div className={`w-[1400px] h-[700px] overflow-hidden ${isOpacity() ? 'bg-gray-900' : 'bg-white'} absolute ${exportRef ? 'grid grid-cols-3 gap-0' : 'hidden'}`} ref={pdfRef}>
                        <div className='w-[1400px] h-[700px] overflow-hidden absolute'>
                            <div className='flex justify-between mx-10 my-16'>
                                <p className='text-gray-400 opacity-20 pl-6'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-16'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-6'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-16'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-6'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-16'>Milan india</p>
                            </div>
                            <div className='flex justify-around mx-10 my-16'>
                                <p className='text-gray-400 opacity-20 pl-16'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-6'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-16'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-6'>Milan india</p>
                            </div>
                            <div className='flex justify-between mx-10 my-16'>
                                <p className='text-gray-400 opacity-20 pl-6'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-16'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-6'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-16'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-6'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-16'>Milan india</p>
                            </div>
                            <div className='flex justify-around mx-10 my-16'>
                                <p className='text-gray-400 opacity-20 pl-16'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-6'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-16'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-6'>Milan india</p>
                            </div>
                            <div className='flex justify-between mx-10 my-16'>
                                <p className='text-gray-400 opacity-20 pl-6'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-16'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-6'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-16'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-6'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-16'>Milan india</p>
                            </div>
                            <div className='flex justify-around mx-10 my-16'>
                                <p className='text-gray-400 opacity-20 pl-16'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-6'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-16'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-6'>Milan india</p>
                            </div>
                            <div className='flex justify-between mx-10 my-16'>
                                <p className='text-gray-400 opacity-20 pl-6'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-16'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-6'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-16'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-6'>Milan india</p>
                                <p className='text-gray-400 opacity-20 pl-16'>Milan india</p>
                            </div>
                        </div>
                        <div className='grid grid-rows-2 col-span-1'>
                            <div className='view1 w-full h-full p-4 row-span-1'>
                                <h1 className={`font-bold text-lg font-serif ${isOpacity() ? 'text-white' : 'text-black'}`}>Top Image</h1>
                                <img src={topUrl} alt="No Image" className='object-contain max-h-60' />
                            </div>
                            <div className='view2 w-full h-full p-4 row-span-1'>
                                <h1 className={`font-bold text-lg font-serif ${isOpacity() ? 'text-white' : 'text-black'}`}>Bottom Image</h1>
                                <img src={bottomUrl} alt="No Image" className='object-contain max-h-60' />
                            </div>
                        </div>
                        <div className='flex flex-col col-span-2'>
                            <div className='grid grid-cols-2'>
                                <div className='w-full h-full p-4 col-span-1'>
                                    <h1 className={`font-bold text-lg font-serif ${isOpacity() ? 'text-white' : 'text-black'}`}>Front Image</h1>
                                    <img src={frontUrl} alt="No Image" className='object-contain max-h-60' />
                                </div>
                                <div className='w-full h-full p-4 col-span-1'>
                                    <h1 className={`font-bold text-lg font-serif ${isOpacity() ? 'text-white' : 'text-black'}`}>Side Image</h1>
                                    <img src={sideUrl} alt="No Image" className='object-contain max-h-60' />
                                </div>
                            </div>
                            <div className=''>
                                <div className='w-full h-full p-4 overflow-hidden flex items-center justify-center'>
                                    <img src={orthoUrl} alt="No Image" className='object-contain h-[360px]' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Suspense fallback={null}>
                        {
                            (() => {
                                switch (productData.id.toString()) {
                                    case "1":
                                        return <>
                                            {
                                                !isPdf && <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0.5, 1], fov: 50, near: 0.001 }} onCreated={(state) => (state.gl.shadowMap.autoUpdate = false)}>
                                                    <ambientLight intensity={2} />
                                                    <spotLight position={[1, 6, 1.5]} angle={0.2} penumbra={1} intensity={6} castShadow shadow-mapSize={[2048, 2048]} />
                                                    <spotLight position={[-5, 5, -1.5]} angle={0.2} penumbra={1} intensity={4} castShadow shadow-mapSize={[1024, 1024]} />
                                                    <Suspense fallback={null}>
                                                        <Product0 onExport={doneExport} onLoad={onLoading} key={key} product={productData} exportRe={exportRef} formData={formData} copacity={opacityData} scale={0.01} position={[0, 0, 0]} texture={textFile} />
                                                        <ContactShadows frames={1} rotation-x={[Math.PI / 2]} position={[0, -0.33, 0]} far={0.4} width={2} height={2} blur={4} />
                                                        {/* <Environment background={false} preset="apartment" /> */}
                                                    </Suspense>
                                                    <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                                                        <GizmoViewcube />
                                                    </GizmoHelper>
                                                    <PerspectiveCamera makeDefault position={[0, 0.5, 1]} fov={50} near={0.001} />
                                                    <OrbitControls zoomSpeed={2} rotateSpeed={2} dampingFactor={0.5} minPolarAngle={-Math.PI / 4} maxPolarAngle={Math.PI / 1} makeDefault />
                                                </Canvas>
                                            }
                                        </>;
                                    case "2":
                                        return <>
                                            {
                                                !isPdf && <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0.5, 1], fov: 50, near: 0.001 }} onCreated={(state) => (state.gl.shadowMap.autoUpdate = false)}>
                                                    <ambientLight intensity={2} />
                                                    <spotLight position={[1, 6, 1.5]} angle={0.2} penumbra={1} intensity={6} castShadow shadow-mapSize={[2048, 2048]} />
                                                    <spotLight position={[-5, 5, -1.5]} angle={0.2} penumbra={1} intensity={4} castShadow shadow-mapSize={[1024, 1024]} />
                                                    <Suspense fallback={null}>
                                                        <Product1 onExport={doneExport} onLoad={onLoading} key={key} product={productData} exportRe={exportRef} formData={formData} copacity={opacityData} scale={1} position={[0, 0, 0]} texture={textFile} />
                                                        <ContactShadows frames={1} rotation-x={[Math.PI / 2]} position={[0, -0.33, 0]} far={0.4} width={2} height={2} blur={4} />
                                                        {/* <Environment background={false} preset="apartment" /> */}
                                                    </Suspense>
                                                    <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                                                        <GizmoViewcube />
                                                    </GizmoHelper>
                                                    <PerspectiveCamera makeDefault position={[0, 0.5, 1]} fov={50} near={0.001} />
                                                    <OrbitControls zoomSpeed={2} rotateSpeed={2} dampingFactor={0.5} minPolarAngle={-Math.PI / 4} maxPolarAngle={Math.PI / 1} makeDefault />
                                                </Canvas>
                                            }
                                        </>;
                                    case "3":
                                        return <>
                                            {
                                                !isPdf && <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0.5, 1], fov: 50, near: 0.001 }} onCreated={(state) => (state.gl.shadowMap.autoUpdate = false)}>
                                                    <ambientLight intensity={globalLight} color={0xFFFFFF} />
                                                    <directionalLight intensity={directionAIntencity} color={0xFFFFFF} position={directionAPosition} />
                                                    <directionalLight intensity={directionBIntencity} color={0xFFFFFF} position={directionBPosition} />
                                                    <pointLight position={[0, 1, 0]} intensity={pointLightA} />
                                                    <pointLight position={[0, -1, 0]} intensity={pointLightB} />
                                                    <spotLight position={[1, 6, 1.5]} angle={0.2} penumbra={1} intensity={0.6} castShadow shadow-mapSize={[2048, 2048]} />
                                                    <Suspense fallback={null}>
                                                        <Product2 onExport={doneExport} onLoad={onLoading} key={key} product={productData} exportRe={exportRef} formData={formData} copacity={opacityData} scale={1} position={[0, 0, 0]} texture={textFile} />
                                                        <ContactShadows frames={1} rotation-x={[Math.PI / 2]} position={[0, -0.33, 0]} far={0.4} width={2} height={2} blur={4} />
                                                        {/* <Environment background={false} preset="apartment" /> */}
                                                    </Suspense>
                                                    <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                                                        <GizmoViewcube />
                                                    </GizmoHelper>
                                                    <PerspectiveCamera makeDefault position={[0, 0.5, 1]} fov={50} near={0.001} />
                                                    <OrbitControls zoomSpeed={2} rotateSpeed={2} dampingFactor={0.5} minPolarAngle={-Math.PI / 4} maxPolarAngle={Math.PI / 1} makeDefault />
                                                </Canvas>
                                            }
                                        </>;
                                    case "4":
                                        return <>
                                            {
                                                !isPdf && <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0.5, 1], fov: 50, near: 0.001 }} linear={true}>
                                                    <ambientLight intensity={globalLight} color={0xFFFFFF} />
                                                    <directionalLight intensity={directionAIntencity} color={0xFFFFFF} position={directionAPosition} />
                                                    <directionalLight intensity={directionBIntencity} color={0xFFFFFF} position={directionBPosition} />
                                                    <pointLight position={[0, 1, 0]} intensity={pointLightA} />
                                                    <pointLight position={[0, -1, 0]} intensity={pointLightB} />
                                                    <spotLight position={[1, 6, 1.5]} angle={0.2} penumbra={1} intensity={0.6} castShadow shadow-mapSize={[2048, 2048]} />
                                                    <Suspense fallback={null}>
                                                        <Product3 onExport={doneExport} onLoad={onLoading} key={key} product={productData} exportRe={exportRef} formData={formData} copacity={opacityData} scale={1} position={[0, 0, 0]} texture={textFile} />
                                                        <ContactShadows frames={1} rotation-x={[Math.PI / 2]} position={[0, -0.33, 0]} far={0.4} width={2} height={2} blur={4} />
                                                        {/* <Environment preset="park" background={true} /> */}
                                                    </Suspense>
                                                    <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                                                        <GizmoViewcube />
                                                    </GizmoHelper>
                                                    <PerspectiveCamera makeDefault position={[0, 0.5, 1]} fov={50} near={0.001} />
                                                    <OrbitControls zoomSpeed={2} rotateSpeed={2} dampingFactor={0.5} minPolarAngle={-Math.PI / 4} maxPolarAngle={Math.PI / 1} makeDefault />
                                                </Canvas>
                                            }
                                        </>;
                                    case "5":
                                        return <>
                                            {
                                                !isPdf && <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0.5, 1], fov: 50, near: 0.001 }} onCreated={(state) => (state.gl.shadowMap.autoUpdate = false)}>
                                                    <ambientLight intensity={globalLight} color={0xFFFFFF} />
                                                    <directionalLight intensity={directionAIntencity} color={0xFFFFFF} position={directionAPosition} />
                                                    <directionalLight intensity={directionBIntencity} color={0xFFFFFF} position={directionBPosition} />
                                                    <pointLight position={[0, 1, 0]} intensity={pointLightA} />
                                                    <pointLight position={[0, -1, 0]} intensity={pointLightB} />
                                                    <spotLight position={[1, 6, 1.5]} angle={0.2} penumbra={1} intensity={0.6} castShadow shadow-mapSize={[2048, 2048]} />
                                                    <Suspense fallback={null}>
                                                        <Product4 onExport={doneExport} onLoad={onLoading} key={key} product={productData} exportRe={exportRef} formData={formData} copacity={opacityData} scale={1} position={[0, 0, 0]} texture={textFile} />
                                                        <ContactShadows frames={1} rotation-x={[Math.PI / 2]} position={[0, -0.33, 0]} far={0.4} width={2} height={2} blur={4} />
                                                        {/* <Environment background={false} preset="apartment" /> */}
                                                    </Suspense>
                                                    <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                                                        <GizmoViewcube />
                                                    </GizmoHelper>
                                                    <PerspectiveCamera makeDefault position={[0, 0.5, 1]} fov={50} near={0.001} />
                                                    <OrbitControls zoomSpeed={2} rotateSpeed={2} dampingFactor={0.5} minPolarAngle={-Math.PI / 4} maxPolarAngle={Math.PI / 1} makeDefault />
                                                </Canvas>
                                            }
                                        </>;
                                    case "6":
                                        return <>
                                            {
                                                !isPdf && <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0.5, 1], fov: 50, near: 0.001 }} linear={true}>
                                                    <ambientLight intensity={globalLight} color={0xFFFFFF} />
                                                    <directionalLight intensity={directionAIntencity} color={0xFFFFFF} position={directionAPosition} />
                                                    <directionalLight intensity={directionBIntencity} color={0xFFFFFF} position={directionBPosition} />
                                                    <pointLight position={[0, 1, 0]} intensity={pointLightA} />
                                                    <pointLight position={[0, -1, 0]} intensity={pointLightB} />
                                                    <spotLight position={[1, 6, 1.5]} angle={0.2} penumbra={1} intensity={0.6} castShadow shadow-mapSize={[2048, 2048]} />
                                                    <Suspense fallback={null}>
                                                        <Product5 onExport={doneExport} onLoad={onLoading} key={key} product={productData} exportRe={exportRef} formData={formData} copacity={opacityData} scale={2} position={[0, 0, 0]} texture={textFile} />
                                                        <ContactShadows frames={1} rotation-x={[Math.PI / 2]} position={[0, -0.33, 0]} far={0.4} width={2} height={2} blur={4} />
                                                        {/* <Environment background={false} preset="apartment" /> */}
                                                    </Suspense>
                                                    <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                                                        <GizmoViewcube />
                                                    </GizmoHelper>
                                                    <PerspectiveCamera makeDefault position={[0, 0.5, 1]} fov={50} near={0.001} />
                                                    <OrbitControls zoomSpeed={2} rotateSpeed={2} dampingFactor={0.5} minPolarAngle={-Math.PI / 4} maxPolarAngle={Math.PI / 1} makeDefault />
                                                </Canvas>
                                            }
                                        </>;
                                    case "7":
                                        return <>
                                            {
                                                !isPdf && <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0.5, 1], fov: 50, near: 0.001 }} linear={true}>
                                                    <ambientLight intensity={globalLight} color={0xFFFFFF} />
                                                    <directionalLight intensity={directionAIntencity} color={0xFFFFFF} position={directionAPosition} />
                                                    <directionalLight intensity={directionBIntencity} color={0xFFFFFF} position={directionBPosition} />
                                                    <pointLight position={[0, 1, 0]} intensity={pointLightA} />
                                                    <pointLight position={[0, -1, 0]} intensity={pointLightB} />
                                                    <spotLight position={[1, 6, 1.5]} angle={0.2} penumbra={1} intensity={0.6} castShadow shadow-mapSize={[2048, 2048]} />
                                                    <Suspense fallback={null}>
                                                        <Product6 onExport={doneExport} onLoad={onLoading} key={key} product={productData} exportRe={exportRef} formData={formData} copacity={opacityData} scale={0.01} position={[0, 0, 0]} texture={textFile} />
                                                        <ContactShadows frames={1} rotation-x={[Math.PI / 2]} position={[0, -0.33, 0]} far={0.4} width={2} height={2} blur={4} />
                                                        {/* <Environment background={false} preset="apartment" /> */}
                                                    </Suspense>
                                                    <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                                                        <GizmoViewcube />
                                                    </GizmoHelper>
                                                    <PerspectiveCamera makeDefault position={[0, 0.5, 1]} fov={50} near={0.001} />
                                                    <OrbitControls zoomSpeed={2} rotateSpeed={2} dampingFactor={0.5} minPolarAngle={-Math.PI / 4} maxPolarAngle={Math.PI / 1} makeDefault />
                                                </Canvas>
                                            }
                                        </>;
                                    case "8":
                                        return <>
                                            {
                                                !isPdf && <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0.5, 1], fov: 50, near: 0.001 }} linear={true}>
                                                    <ambientLight intensity={globalLight} color={0xFFFFFF} />
                                                    <directionalLight intensity={directionAIntencity} color={0xFFFFFF} position={directionAPosition} />
                                                    <directionalLight intensity={directionBIntencity} color={0xFFFFFF} position={directionBPosition} />
                                                    <pointLight position={[0, 1, 0]} intensity={pointLightA} />
                                                    <pointLight position={[0, -1, 0]} intensity={pointLightB} />
                                                    <spotLight position={[1, 6, 1.5]} angle={0.2} penumbra={1} intensity={0.6} castShadow shadow-mapSize={[2048, 2048]} />
                                                    <Suspense fallback={null}>
                                                        <Product7 onExport={doneExport} onLoad={onLoading} key={key} product={productData} exportRe={exportRef} formData={formData} copacity={opacityData} scale={0.01} position={[0, 0, 0]} texture={textFile} />
                                                        <ContactShadows frames={1} rotation-x={[Math.PI / 2]} position={[0, -0.33, 0]} far={0.4} width={2} height={2} blur={4} />
                                                        {/* <Environment background={false} preset="apartment" /> */}
                                                    </Suspense>
                                                    <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                                                        <GizmoViewcube />
                                                    </GizmoHelper>
                                                    <PerspectiveCamera makeDefault position={[0, 0.5, 1]} fov={50} near={0.001} />
                                                    <OrbitControls zoomSpeed={2} rotateSpeed={2} dampingFactor={0.5} minPolarAngle={-Math.PI / 4} maxPolarAngle={Math.PI / 1} makeDefault />
                                                </Canvas>
                                            }
                                        </>;
                                    case "9":
                                        return <>
                                            {
                                                !isPdf && <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0.5, 1], fov: 50, near: 0.001 }} linear={true}>
                                                    <ambientLight intensity={globalLight} color={0xFFFFFF} />
                                                    <directionalLight intensity={directionAIntencity} color={0xFFFFFF} position={directionAPosition} />
                                                    <directionalLight intensity={directionBIntencity} color={0xFFFFFF} position={directionBPosition} />
                                                    <pointLight position={[0, 1, 0]} intensity={pointLightA} />
                                                    <pointLight position={[0, -1, 0]} intensity={pointLightB} />
                                                    <spotLight position={[1, 6, 1.5]} angle={0.2} penumbra={1} intensity={0.6} castShadow shadow-mapSize={[2048, 2048]} />
                                                    <Suspense fallback={null}>
                                                        <Product8 onExport={doneExport} onLoad={onLoading} key={key} product={productData} exportRe={exportRef} formData={formData} copacity={opacityData} scale={0.01} position={[0, 0, 0]} texture={textFile} />
                                                        <ContactShadows frames={1} rotation-x={[Math.PI / 2]} position={[0, -0.33, 0]} far={0.4} width={2} height={2} blur={4} />
                                                        {/* <Environment background={false} preset="apartment" /> */}
                                                    </Suspense>
                                                    <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                                                        <GizmoViewcube />
                                                    </GizmoHelper>
                                                    <PerspectiveCamera makeDefault position={[0, 0.5, 1]} fov={50} near={0.001} />
                                                    <OrbitControls zoomSpeed={2} rotateSpeed={2} dampingFactor={0.5} minPolarAngle={-Math.PI / 4} maxPolarAngle={Math.PI / 1} makeDefault />
                                                </Canvas>
                                            }
                                        </>;
                                    case "10":
                                        return <>
                                            {
                                                !isPdf && <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0.5, 1], fov: 50, near: 0.001 }} linear={true}>
                                                    <ambientLight intensity={globalLight} color={0xFFFFFF} />
                                                    <directionalLight intensity={directionAIntencity} color={0xFFFFFF} position={directionAPosition} />
                                                    <directionalLight intensity={directionBIntencity} color={0xFFFFFF} position={directionBPosition} />
                                                    <pointLight position={[0, 1, 0]} intensity={pointLightA} />
                                                    <pointLight position={[0, -1, 0]} intensity={pointLightB} />
                                                    <spotLight position={[1, 6, 1.5]} angle={0.2} penumbra={1} intensity={0.6} castShadow shadow-mapSize={[2048, 2048]} />
                                                    <Suspense fallback={null}>
                                                        <Product9 onExport={doneExport} onLoad={onLoading} key={key} product={productData} exportRe={exportRef} formData={formData} copacity={opacityData} scale={0.01} position={[0, 0, 0]} texture={textFile} />
                                                        <ContactShadows frames={1} rotation-x={[Math.PI / 2]} position={[0, -0.33, 0]} far={0.4} width={2} height={2} blur={4} />
                                                        {/* <Environment background={false} preset="apartment" /> */}
                                                    </Suspense>
                                                    <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                                                        <GizmoViewcube />
                                                    </GizmoHelper>
                                                    <PerspectiveCamera makeDefault position={[0, 0.5, 1]} fov={50} near={0.001} />
                                                    <OrbitControls zoomSpeed={2} rotateSpeed={2} dampingFactor={0.5} minPolarAngle={-Math.PI / 4} maxPolarAngle={Math.PI / 1} makeDefault />
                                                </Canvas>
                                            }
                                        </>;
                                    case "11":
                                        return <>
                                            {
                                                !isPdf && <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0.5, 1], fov: 50, near: 0.001 }} linear={true}>
                                                    <ambientLight intensity={globalLight} color={0xFFFFFF} />
                                                    <directionalLight intensity={directionAIntencity} color={0xFFFFFF} position={directionAPosition} />
                                                    <directionalLight intensity={directionBIntencity} color={0xFFFFFF} position={directionBPosition} />
                                                    <pointLight position={[0, 1, 0]} intensity={pointLightA} />
                                                    <pointLight position={[0, -1, 0]} intensity={pointLightB} />
                                                    <spotLight position={[1, 6, 1.5]} angle={0.2} penumbra={1} intensity={0.6} castShadow shadow-mapSize={[2048, 2048]} />
                                                    <Suspense fallback={null}>
                                                        <Product10 onExport={doneExport} onLoad={onLoading} key={key} product={productData} exportRe={exportRef} formData={formData} copacity={opacityData} scale={0.01} position={[0, 0, 0]} texture={textFile} />
                                                        <ContactShadows frames={1} rotation-x={[Math.PI / 2]} position={[0, -0.33, 0]} far={0.4} width={2} height={2} blur={4} />
                                                        {/* <Environment background={false} preset="apartment" /> */}
                                                    </Suspense>
                                                    <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                                                        <GizmoViewcube />
                                                    </GizmoHelper>
                                                    <PerspectiveCamera makeDefault position={[0, 0.5, 1]} fov={50} near={0.001} />
                                                    <OrbitControls zoomSpeed={2} rotateSpeed={2} dampingFactor={0.5} minPolarAngle={-Math.PI / 4} maxPolarAngle={Math.PI / 1} makeDefault />
                                                </Canvas>
                                            }
                                        </>;
                                    case "12":
                                        return <>
                                            {
                                                !isPdf && <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0.5, 1], fov: 50, near: 0.001 }} linear={true}>
                                                    <ambientLight intensity={globalLight} color={0xFFFFFF} />
                                                    <directionalLight intensity={directionAIntencity} color={0xFFFFFF} position={directionAPosition} />
                                                    <directionalLight intensity={directionBIntencity} color={0xFFFFFF} position={directionBPosition} />
                                                    <pointLight position={[0, 1, 0]} intensity={pointLightA} />
                                                    <pointLight position={[0, -1, 0]} intensity={pointLightB} />
                                                    <spotLight position={[1, 6, 1.5]} angle={0.2} penumbra={1} intensity={0.6} castShadow shadow-mapSize={[2048, 2048]} />
                                                    <Suspense fallback={null}>
                                                        <Product11 onExport={doneExport} onLoad={onLoading} key={key} product={productData} exportRe={exportRef} formData={formData} copacity={opacityData} scale={0.01} position={[0, 0, 0]} texture={textFile} />
                                                        <ContactShadows frames={1} rotation-x={[Math.PI / 2]} position={[0, -0.33, 0]} far={0.4} width={2} height={2} blur={4} />
                                                        {/* <Environment background={false} preset="apartment" /> */}
                                                    </Suspense>
                                                    <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                                                        <GizmoViewcube />
                                                    </GizmoHelper>
                                                    <PerspectiveCamera makeDefault position={[0, 0.5, 1]} fov={50} near={0.001} />
                                                    <OrbitControls zoomSpeed={2} rotateSpeed={2} dampingFactor={0.5} minPolarAngle={-Math.PI / 4} maxPolarAngle={Math.PI / 1} makeDefault />
                                                </Canvas>
                                            }
                                        </>;
                                    case "13":
                                        return <>
                                            {
                                                !isPdf && <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0.5, 1], fov: 50, near: 0.001 }} linear={true}>
                                                    <ambientLight intensity={globalLight} color={0xFFFFFF} />
                                                    <directionalLight intensity={directionAIntencity} color={0xFFFFFF} position={directionAPosition} />
                                                    <directionalLight intensity={directionBIntencity} color={0xFFFFFF} position={directionBPosition} />
                                                    <pointLight position={[0, 1, 0]} intensity={pointLightA} />
                                                    <pointLight position={[0, -1, 0]} intensity={pointLightB} />
                                                    <spotLight position={[1, 6, 1.5]} angle={0.2} penumbra={1} intensity={0.6} castShadow shadow-mapSize={[2048, 2048]} />
                                                    <Suspense fallback={null}>
                                                        <Product12 onExport={doneExport} onLoad={onLoading} key={key} product={productData} exportRe={exportRef} formData={formData} copacity={opacityData} scale={0.01} position={[0, 0, 0]} texture={textFile} />
                                                        <ContactShadows frames={1} rotation-x={[Math.PI / 2]} position={[0, -0.33, 0]} far={0.4} width={2} height={2} blur={4} />
                                                        {/* <Environment background={false} preset="apartment" /> */}
                                                    </Suspense>
                                                    <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                                                        <GizmoViewcube />
                                                    </GizmoHelper>
                                                    <PerspectiveCamera makeDefault position={[0, 0.5, 1]} fov={50} near={0.001} />
                                                    <OrbitControls zoomSpeed={2} rotateSpeed={2} dampingFactor={0.5} minPolarAngle={-Math.PI / 4} maxPolarAngle={Math.PI / 1} makeDefault />
                                                </Canvas>
                                            }
                                        </>;
                                    case "14":
                                        return <>
                                            {
                                                !isPdf && <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0.5, 1], fov: 50, near: 0.001 }} linear={true}>
                                                    <ambientLight intensity={globalLight} color={0xFFFFFF} />
                                                    <directionalLight intensity={directionAIntencity} color={0xFFFFFF} position={directionAPosition} />
                                                    <directionalLight intensity={directionBIntencity} color={0xFFFFFF} position={directionBPosition} />
                                                    <pointLight position={[0, 1, 0]} intensity={pointLightA} />
                                                    <pointLight position={[0, -1, 0]} intensity={pointLightB} />
                                                    <spotLight position={[1, 6, 1.5]} angle={0.2} penumbra={1} intensity={0.6} castShadow shadow-mapSize={[2048, 2048]} />
                                                    <Suspense fallback={null}>
                                                        <Product13 onExport={doneExport} onLoad={onLoading} key={key} product={productData} exportRe={exportRef} formData={formData} copacity={opacityData} scale={0.01} position={[0, 0, 0]} texture={textFile} />
                                                        <ContactShadows frames={1} rotation-x={[Math.PI / 2]} position={[0, -0.33, 0]} far={0.4} width={2} height={2} blur={4} />
                                                        {/* <Environment background={false} preset="apartment" /> */}
                                                    </Suspense>
                                                    <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                                                        <GizmoViewcube />
                                                    </GizmoHelper>
                                                    <PerspectiveCamera makeDefault position={[0, 0.5, 1]} fov={50} near={0.001} />
                                                    <OrbitControls zoomSpeed={2} rotateSpeed={2} dampingFactor={0.5} minPolarAngle={-Math.PI / 4} maxPolarAngle={Math.PI / 1} makeDefault />
                                                </Canvas>
                                            }
                                        </>;
                                    case "15":
                                        return <>
                                            {
                                                !isPdf && <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0.5, 1], fov: 50, near: 0.001 }} linear={true}>
                                                    <ambientLight intensity={globalLight} color={0xFFFFFF} />
                                                    <directionalLight intensity={directionAIntencity} color={0xFFFFFF} position={directionAPosition} />
                                                    <directionalLight intensity={directionBIntencity} color={0xFFFFFF} position={directionBPosition} />
                                                    <pointLight position={[0, 1, 0]} intensity={pointLightA} />
                                                    <pointLight position={[0, -1, 0]} intensity={pointLightB} />
                                                    <spotLight position={[1, 6, 1.5]} angle={0.2} penumbra={1} intensity={0.6} castShadow shadow-mapSize={[2048, 2048]} />
                                                    <Suspense fallback={null}>
                                                        <Product14 onExport={doneExport} onLoad={onLoading} key={key} product={productData} exportRe={exportRef} formData={formData} copacity={opacityData} scale={0.01} position={[0, 0, 0]} texture={textFile} />
                                                        <ContactShadows frames={1} rotation-x={[Math.PI / 2]} position={[0, -0.33, 0]} far={0.4} width={2} height={2} blur={4} />
                                                        {/* <Environment background={false} preset="apartment" /> */}
                                                    </Suspense>
                                                    <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                                                        <GizmoViewcube />
                                                    </GizmoHelper>
                                                    <PerspectiveCamera makeDefault position={[0, 0.5, 1]} fov={50} near={0.001} />
                                                    <OrbitControls zoomSpeed={2} rotateSpeed={2} dampingFactor={0.5} minPolarAngle={-Math.PI / 4} maxPolarAngle={Math.PI / 1} makeDefault />
                                                </Canvas>
                                            }
                                        </>;
                                    case "16":
                                        return <>
                                            {
                                                !isPdf && <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0.5, 1], fov: 50, near: 0.001 }} linear={true}>
                                                    <ambientLight intensity={globalLight} color={0xFFFFFF} />
                                                    <directionalLight intensity={directionAIntencity} color={0xFFFFFF} position={directionAPosition} />
                                                    <directionalLight intensity={directionBIntencity} color={0xFFFFFF} position={directionBPosition} />
                                                    <pointLight position={[0, 1, 0]} intensity={pointLightA} />
                                                    <pointLight position={[0, -1, 0]} intensity={pointLightB} />
                                                    <spotLight position={[1, 6, 1.5]} angle={0.2} penumbra={1} intensity={0.6} castShadow shadow-mapSize={[2048, 2048]} />
                                                    <Suspense fallback={null}>
                                                        <Product15 onExport={doneExport} onLoad={onLoading} key={key} product={productData} exportRe={exportRef} formData={formData} copacity={opacityData} scale={0.01} position={[0, 0, 0]} texture={textFile} />
                                                        <ContactShadows frames={1} rotation-x={[Math.PI / 2]} position={[0, -0.33, 0]} far={0.4} width={2} height={2} blur={4} />
                                                        {/* <Environment background={false} preset="apartment" /> */}
                                                    </Suspense>
                                                    <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                                                        <GizmoViewcube />
                                                    </GizmoHelper>
                                                    <PerspectiveCamera makeDefault position={[0, 0.5, 1]} fov={50} near={0.001} />
                                                    <OrbitControls zoomSpeed={2} rotateSpeed={2} dampingFactor={0.5} minPolarAngle={-Math.PI / 4} maxPolarAngle={Math.PI / 1} makeDefault />
                                                </Canvas>
                                            }
                                        </>;
                                    case "17":
                                        return <>
                                            {
                                                !isPdf && <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0.5, 1], fov: 50, near: 0.001 }} linear={true}>
                                                    <ambientLight intensity={globalLight} color={0xFFFFFF} />
                                                    <directionalLight intensity={directionAIntencity} color={0xFFFFFF} position={directionAPosition} />
                                                    <directionalLight intensity={directionBIntencity} color={0xFFFFFF} position={directionBPosition} />
                                                    <pointLight position={[0, 1, 0]} intensity={pointLightA} />
                                                    <pointLight position={[0, -1, 0]} intensity={pointLightB} />
                                                    <spotLight position={[1, 6, 1.5]} angle={0.2} penumbra={1} intensity={0.6} castShadow shadow-mapSize={[2048, 2048]} />
                                                    <Suspense fallback={null}>
                                                        <Product16 onExport={doneExport} onLoad={onLoading} key={key} product={productData} exportRe={exportRef} formData={formData} copacity={opacityData} scale={0.01} position={[0, 0, 0]} texture={textFile} />
                                                        <ContactShadows frames={1} rotation-x={[Math.PI / 2]} position={[0, -0.33, 0]} far={0.4} width={2} height={2} blur={4} />
                                                        {/* <Environment background={false} preset="apartment" /> */}
                                                    </Suspense>
                                                    <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                                                        <GizmoViewcube />
                                                    </GizmoHelper>
                                                    <PerspectiveCamera makeDefault position={[0, 0.5, 1]} fov={50} near={0.001} />
                                                    <OrbitControls zoomSpeed={2} rotateSpeed={2} dampingFactor={0.5} minPolarAngle={-Math.PI / 4} maxPolarAngle={Math.PI / 1} makeDefault />
                                                </Canvas>
                                            }
                                        </>;
                                    case "18":
                                        return <>
                                            {
                                                !isPdf && <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0.5, 1], fov: 50, near: 0.001 }} linear={true}>
                                                    <ambientLight intensity={globalLight} color={0xFFFFFF} />
                                                    <directionalLight intensity={directionAIntencity} color={0xFFFFFF} position={directionAPosition} />
                                                    <directionalLight intensity={directionBIntencity} color={0xFFFFFF} position={directionBPosition} />
                                                    <pointLight position={[0, 1, 0]} intensity={pointLightA} />
                                                    <pointLight position={[0, -1, 0]} intensity={pointLightB} />
                                                    <spotLight position={[1, 6, 1.5]} angle={0.2} penumbra={1} intensity={0.6} castShadow shadow-mapSize={[2048, 2048]} />
                                                    <Suspense fallback={null}>
                                                        <Product17 onExport={doneExport} onLoad={onLoading} key={key} product={productData} exportRe={exportRef} formData={formData} copacity={opacityData} scale={0.01} position={[0, 0, 0]} texture={textFile} />
                                                        <ContactShadows frames={1} rotation-x={[Math.PI / 2]} position={[0, -0.33, 0]} far={0.4} width={2} height={2} blur={4} />
                                                        {/* <Environment background={false} preset="apartment" /> */}
                                                    </Suspense>
                                                    <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                                                        <GizmoViewcube />
                                                    </GizmoHelper>
                                                    <PerspectiveCamera makeDefault position={[0, 0.5, 1]} fov={50} near={0.001} />
                                                    <OrbitControls zoomSpeed={2} rotateSpeed={2} dampingFactor={0.5} minPolarAngle={-Math.PI / 4} maxPolarAngle={Math.PI / 1} makeDefault />
                                                </Canvas>
                                            }
                                        </>;
                                    case "19":
                                        return <>
                                            {
                                                !isPdf && <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0.5, 1], fov: 50, near: 0.001 }} linear={true}>
                                                    <ambientLight intensity={globalLight} color={0xFFFFFF} />
                                                    <directionalLight intensity={directionAIntencity} color={0xFFFFFF} position={directionAPosition} />
                                                    <directionalLight intensity={directionBIntencity} color={0xFFFFFF} position={directionBPosition} />
                                                    <pointLight position={[0, 1, 0]} intensity={pointLightA} />
                                                    <pointLight position={[0, -1, 0]} intensity={pointLightB} />
                                                    <spotLight position={[1, 6, 1.5]} angle={0.2} penumbra={1} intensity={0.6} castShadow shadow-mapSize={[2048, 2048]} />
                                                    <Suspense fallback={null}>
                                                        <Product18 onExport={doneExport} onLoad={onLoading} key={key} product={productData} exportRe={exportRef} formData={formData} copacity={opacityData} scale={0.01} position={[0, 0, 0]} texture={textFile} />
                                                        <ContactShadows frames={1} rotation-x={[Math.PI / 2]} position={[0, -0.33, 0]} far={0.4} width={2} height={2} blur={4} />
                                                        {/* <Environment background={false} preset="apartment" /> */}
                                                    </Suspense>
                                                    <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                                                        <GizmoViewcube />
                                                    </GizmoHelper>
                                                    <PerspectiveCamera makeDefault position={[0, 0.5, 1]} fov={50} near={0.001} />
                                                    <OrbitControls zoomSpeed={2} rotateSpeed={2} dampingFactor={0.5} minPolarAngle={-Math.PI / 4} maxPolarAngle={Math.PI / 1} makeDefault />
                                                </Canvas>
                                            }
                                        </>;
                                    case "20":
                                        return <>
                                            {
                                                !isPdf && <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0.5, 1], fov: 50, near: 0.001 }} linear={true}>
                                                    <ambientLight intensity={globalLight} color={0xFFFFFF} />
                                                    <directionalLight intensity={directionAIntencity} color={0xFFFFFF} position={directionAPosition} />
                                                    <directionalLight intensity={directionBIntencity} color={0xFFFFFF} position={directionBPosition} />
                                                    <pointLight position={[0, 1, 0]} intensity={pointLightA} />
                                                    <pointLight position={[0, -1, 0]} intensity={pointLightB} />
                                                    <spotLight position={[1, 6, 1.5]} angle={0.2} penumbra={1} intensity={0.6} castShadow shadow-mapSize={[2048, 2048]} />
                                                    <Suspense fallback={null}>
                                                        <Product19 onExport={doneExport} onLoad={onLoading} key={key} product={productData} exportRe={exportRef} formData={formData} copacity={opacityData} scale={0.01} position={[0, 0, 0]} texture={textFile} />
                                                        <ContactShadows frames={1} rotation-x={[Math.PI / 2]} position={[0, -0.33, 0]} far={0.4} width={2} height={2} blur={4} />
                                                        {/* <Environment background={false} preset="apartment" /> */}
                                                    </Suspense>
                                                    <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                                                        <GizmoViewcube />
                                                    </GizmoHelper>
                                                    <PerspectiveCamera makeDefault position={[0, 0.5, 1]} fov={50} near={0.001} />
                                                    <OrbitControls zoomSpeed={2} rotateSpeed={2} dampingFactor={0.5} minPolarAngle={-Math.PI / 4} maxPolarAngle={Math.PI / 1} makeDefault />
                                                </Canvas>
                                            }
                                        </>;
                                    case "21":
                                        return <>
                                            {
                                                !isPdf && <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0.5, 1], fov: 50, near: 0.001 }} linear={true}>
                                                    <ambientLight intensity={globalLight} color={0xFFFFFF} />
                                                    <directionalLight intensity={directionAIntencity} color={0xFFFFFF} position={directionAPosition} />
                                                    <directionalLight intensity={directionBIntencity} color={0xFFFFFF} position={directionBPosition} />
                                                    <pointLight position={[0, 1, 0]} intensity={pointLightA} />
                                                    <pointLight position={[0, -1, 0]} intensity={pointLightB} />
                                                    <spotLight position={[1, 6, 1.5]} angle={0.2} penumbra={1} intensity={0.6} castShadow shadow-mapSize={[2048, 2048]} />
                                                    <Suspense fallback={null}>
                                                        <Product20 onExport={doneExport} onLoad={onLoading} key={key} product={productData} exportRe={exportRef} formData={formData} copacity={opacityData} scale={0.01} position={[0, 0, 0]} texture={textFile} />
                                                        <ContactShadows frames={1} rotation-x={[Math.PI / 2]} position={[0, -0.33, 0]} far={0.4} width={2} height={2} blur={4} />
                                                        {/* <Environment background={false} preset="apartment" /> */}
                                                    </Suspense>
                                                    <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                                                        <GizmoViewcube />
                                                    </GizmoHelper>
                                                    <PerspectiveCamera makeDefault position={[0, 0.5, 1]} fov={50} near={0.001} />
                                                    <OrbitControls zoomSpeed={2} rotateSpeed={2} dampingFactor={0.5} minPolarAngle={-Math.PI / 4} maxPolarAngle={Math.PI / 1} makeDefault />
                                                </Canvas>
                                            }
                                        </>;
                                    case "22":
                                        return <>
                                            {
                                                !isPdf && <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0.5, 1], fov: 50, near: 0.001 }} linear={true}>
                                                    <ambientLight intensity={globalLight} color={0xFFFFFF} />
                                                    <directionalLight intensity={directionAIntencity} color={0xFFFFFF} position={directionAPosition} />
                                                    <directionalLight intensity={directionBIntencity} color={0xFFFFFF} position={directionBPosition} />
                                                    <pointLight position={[0, 1, 0]} intensity={pointLightA} />
                                                    <pointLight position={[0, -1, 0]} intensity={pointLightB} />
                                                    <spotLight position={[1, 6, 1.5]} angle={0.2} penumbra={1} intensity={0.6} castShadow shadow-mapSize={[2048, 2048]} />
                                                    <Suspense fallback={null}>
                                                        <Product21 onExport={doneExport} onLoad={onLoading} key={key} product={productData} exportRe={exportRef} formData={formData} copacity={opacityData} scale={0.01} position={[0, 0, 0]} texture={textFile} />
                                                        <ContactShadows frames={1} rotation-x={[Math.PI / 2]} position={[0, -0.33, 0]} far={0.4} width={2} height={2} blur={4} />
                                                        {/* <Environment background={false} preset="apartment" /> */}
                                                    </Suspense>
                                                    <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                                                        <GizmoViewcube />
                                                    </GizmoHelper>
                                                    <PerspectiveCamera makeDefault position={[0, 0.5, 1]} fov={50} near={0.001} />
                                                    <OrbitControls zoomSpeed={2} rotateSpeed={2} dampingFactor={0.5} minPolarAngle={-Math.PI / 4} maxPolarAngle={Math.PI / 1} makeDefault />
                                                </Canvas>
                                            }
                                        </>;
                                    case "23":
                                        return <>
                                            {
                                                !isPdf && <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0.5, 1], fov: 50, near: 0.001 }} linear={true}>
                                                    <ambientLight intensity={globalLight} color={0xFFFFFF} />
                                                    <directionalLight intensity={directionAIntencity} color={0xFFFFFF} position={directionAPosition} />
                                                    <directionalLight intensity={directionBIntencity} color={0xFFFFFF} position={directionBPosition} />
                                                    <pointLight position={[0, 1, 0]} intensity={pointLightA} />
                                                    <pointLight position={[0, -1, 0]} intensity={pointLightB} />
                                                    <spotLight position={[1, 6, 1.5]} angle={0.2} penumbra={1} intensity={0.6} castShadow shadow-mapSize={[2048, 2048]} />
                                                    <Suspense fallback={null}>
                                                        <Product22 onExport={doneExport} onLoad={onLoading} key={key} product={productData} exportRe={exportRef} formData={formData} copacity={opacityData} scale={0.01} position={[0, 0, 0]} texture={textFile} />
                                                        <ContactShadows frames={1} rotation-x={[Math.PI / 2]} position={[0, -0.33, 0]} far={0.4} width={2} height={2} blur={4} />
                                                        {/* <Environment background={false} preset="apartment" /> */}
                                                    </Suspense>
                                                    <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                                                        <GizmoViewcube />
                                                    </GizmoHelper>
                                                    <PerspectiveCamera makeDefault position={[0, 0.5, 1]} fov={50} near={0.001} />
                                                    <OrbitControls zoomSpeed={2} rotateSpeed={2} dampingFactor={0.5} minPolarAngle={-Math.PI / 4} maxPolarAngle={Math.PI / 1} makeDefault />
                                                </Canvas>
                                            }
                                        </>;
                                    case "24":
                                        return <>
                                            {
                                                !isPdf && <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0.5, 1], fov: 50, near: 0.001 }} linear={true}>
                                                    <ambientLight intensity={globalLight} color={0xFFFFFF} />
                                                    <directionalLight intensity={directionAIntencity} color={0xFFFFFF} position={directionAPosition} />
                                                    <directionalLight intensity={directionBIntencity} color={0xFFFFFF} position={directionBPosition} />
                                                    <pointLight position={[0, 1, 0]} intensity={pointLightA} />
                                                    <pointLight position={[0, -1, 0]} intensity={pointLightB} />
                                                    <spotLight position={[1, 6, 1.5]} angle={0.2} penumbra={1} intensity={0.6} castShadow shadow-mapSize={[2048, 2048]} />
                                                    <Suspense fallback={null}>
                                                        <Product23 onExport={doneExport} onLoad={onLoading} key={key} product={productData} exportRe={exportRef} formData={formData} copacity={opacityData} scale={0.01} position={[0, 0, 0]} texture={textFile} />
                                                        <ContactShadows frames={1} rotation-x={[Math.PI / 2]} position={[0, -0.33, 0]} far={0.4} width={2} height={2} blur={4} />
                                                        {/* <Environment background={false} preset="apartment" /> */}
                                                    </Suspense>
                                                    <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                                                        <GizmoViewcube />
                                                    </GizmoHelper>
                                                    <PerspectiveCamera makeDefault position={[0, 0.5, 1]} fov={50} near={0.001} />
                                                    <OrbitControls zoomSpeed={2} rotateSpeed={2} dampingFactor={0.5} minPolarAngle={-Math.PI / 4} maxPolarAngle={Math.PI / 1} makeDefault />
                                                </Canvas>
                                            }
                                        </>;
                                    default:
                                        break;
                                }
                            })()
                        }
                    </Suspense>
                </div >
            </div >
        </>
    );
}