import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { authAtom } from '../core/services/config/atoms';

import { motion } from "framer-motion";
import { Popover, Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';

import TopBar from '../core/components/ui/TopBar';
import ServiceAuth from '../core/services/repository/serviceAuth';

export default function PageAdminHome() {

    const [authStatus] = useRecoilState(authAtom);

    const [users, setUsers] = useState([]);

    const userService = new ServiceAuth();

    const navigate = useNavigate();

    const loadData = () => {
        if (authStatus) {
            (async function () {
                var res = await userService.getUsers({});
                setUsers(res.data);
            })();
        } else {
            navigate('/', { replace: true });
        }
    }

    const updateUser = (id, status) => {
        let body = {
            "id": id,
            "status": status
        }
        userService.updateUserById(body).then((res) => {
            setUsers(res.data);
        });
    }

    useEffect(() => {
        loadData();
        return () => {
        }
    }, []);

    return (
        <div className='w-full h-screen bg-gray-50 overflow-y-auto'>
            <div className='w-full overflow-hidden'>
                <div className='h-20 bg-blue-400 relative'>
                    <h1 className='mx-4 text-white text-4xl font-medium pt-4'>Admin Home</h1>
                    <TopBar />
                </div>
                <div className='py-4 px-4'>
                    <h2 className='text-2xl text-gray-800 font-medium font-serif my-2'>Users panel</h2>
                    <hr />
                    <div className="intro-y mt-8 mb-8 col-span-12 grid grid-cols-12 gap-2">
                        <div className={`mt-2 col-span-12 shadow-md rounded-md`}>
                            <table className="min-w-full divide-y divide-gray-200 px-4">
                                <thead className="bg-white">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Full Name
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Company Name
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Email ID
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Register At
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Status
                                        </th>
                                        <th scope="col" className="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            -
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {
                                        users.length !== 0 && users.map((user) => {
                                            return <tr className="cursor-pointer hover:bg-gray-100"> {/* onClick={() => { setUser(user) }} */}
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-bold text-gray-900 uppercase">
                                                        {user.fname}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-bold text-gray-900">
                                                        {user.cname}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    <div>
                                                        <div className="text-sm text-gray-500">
                                                            {user.email}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-blue-600">
                                                        {user.createdAt}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${user.status == 'new' ? 'bg-blue-100 text-blue-800' : 'bg-green-100 text-green-800'}`}>
                                                        {user.status.toUpperCase()}
                                                    </span>
                                                </td>
                                                <td>
                                                    <Popover className="relative">
                                                        <Popover.Button>
                                                            <motion.i whileHover={{ scale: 1.1 }} class="las la-ellipsis-v text-lg"></motion.i>
                                                        </Popover.Button>
                                                        <Transition
                                                            enter="transition duration-100 ease-out"
                                                            enterFrom="transform scale-95 opacity-0"
                                                            enterTo="transform scale-100 opacity-100"
                                                            leave="transition duration-75 ease-out"
                                                            leaveFrom="transform scale-100 opacity-100"
                                                            leaveTo="transform scale-95 opacity-0">
                                                            <Popover.Panel>
                                                                <div
                                                                    className='bg-white shadow-md rounded-md absolute' style={{ top: '6px', left: '-136px' }}>
                                                                    <div>
                                                                        <ul className='flex flex-col items-start p-2'>
                                                                            {
                                                                                user.status !== 'new' && <li className='w-32 cursor-pointer hover:bg-red-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                    updateUser(user.id, 'new');
                                                                                }}>
                                                                                    <div className='flex items-center text-base'>
                                                                                        <i className="las la-ban text-lg"></i>
                                                                                        <p className='text-sm font-serif ml-2'>Unverify</p>
                                                                                    </div>
                                                                                </li>
                                                                            }
                                                                            {
                                                                                user.status === 'new' && <li className='w-32 cursor-pointer hover:bg-green-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                    updateUser(user.id, 'verified');
                                                                                }}>
                                                                                    <div className='flex items-center text-base'>
                                                                                        <i className="las la-certificate text-lg"></i>
                                                                                        <p className='text-sm font-serif ml-2'>Verify</p>
                                                                                    </div>
                                                                                </li>
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </Popover.Panel>
                                                        </Transition>
                                                    </Popover>
                                                </td>
                                            </tr>
                                        })
                                    }
                                    {
                                        users.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                            <td className="px-6 py-4 whitespace-nowrap" colSpan="6">
                                                <div className="text-sm text-blue-600 text-center">
                                                    No data found.
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
