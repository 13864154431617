import React, { useState, useEffect } from 'react';

import Lottie from 'lottie-react';
import { useAlert } from 'react-alert';
import { useNavigate, useParams } from 'react-router-dom';

import loginBg from '../assets/images/bg-login.jpg';
import animBtnLoading from '../assets/anim/anim-btnLoading.json';

import ServiceAuth from '../core/services/repository/serviceAuth';

export default function PageAuthReset() {

    const { token } = useParams();

    const [formData, setFormData] = useState({});

    const [id, setID] = useState("");

    const [submit, setSubmit] = useState(false);
    const [reset, setReset] = useState(false);
    const [isError, setIsError] = useState(false);

    const alert = useAlert();
    const navigate = useNavigate();

    const authService = new ServiceAuth();

    const loadData = () => {
        let body = {
            token: token
        };
        authService.verifyPass(body).then((res) => {
            if (res.status) {
                setIsError(false);
                setID(res.data['id']);
            } else {
                alert.show('Error: Invalid verification token', { type: 'error' });
                setIsError(true);
            }
        });
    }

    const gotoLogin = () => {
        navigate('/', { replace: true });
    }

    const onChangeForm = (e) => {
        var { value, name } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmit(true);
        if (formData['password'] === formData['cpassword']) {
            var body = {
                id: id,
                password: formData['password'],
            }
            authService.reset(body, '').then((d) => {
                if (d.status) {
                    setSubmit(false);
                    setReset(true);
                } else {
                    alert.show('Error: ' + d.msg, { type: 'error' });
                    setSubmit(false);
                }
            });
        } else {
            alert.show('Password did not match !', { type: 'error' });
        }
    }

    useEffect(() => {
        loadData();
        return () => {
            console.log("Done");
        }
    }, []);

    return (
        <>
            <div className="w-screen h-screen overflow-hidden relative">
                <div className="xl:grid xl:grid-cols-12 xl:gap-0">
                    <div className="absolute xl:relative w-full xl:col-span-5">
                        <div className="h-screen flex flex-col items-center justify-center xl:bg-blue-50">
                            <div className="shadow-lg rounded-xl px-4 xl:px-10 py-20 xl:py-10 bg-white mt-6 mx-2 max-w-md md:w-auth w-11/12">
                                <h1 className="text-2xl text-black font-serif font-medium">Welcome back!</h1>
                                <h2 className="text-sm text-gray-400 font-serif">We`re so excited to see you again!</h2>

                                {
                                    isError && <div className={`bg-red-100 p-3 rounded-md mt-8`}>
                                        <p className={`text-sm text-red-600 text-center`}>
                                            Invalid verification token<br /> or <br />Token already verifyed please try login
                                        </p>
                                    </div>
                                }

                                {
                                    reset && <>
                                        <div className={`bg-green-100 p-3 rounded-md mt-8`}>
                                            <p className={`text-sm text-green-600 text-center`}>
                                                Password resetted to new one successfully
                                            </p>
                                        </div>
                                        <p className='mt-8 text-sm text-black font-medium text-center'>Back to <span className='text-blue-600 cursor-pointer' onClick={() => gotoLogin()}>Login</span></p>
                                    </>
                                }

                                {
                                    !isError && !reset && <form className="mt-8" onSubmit={handleSubmit}>
                                        <div className="flex flex-col">
                                            <label className="text-sm text-gray-900 font-serif">Password
                                                <sup className="text-red-600">*</sup>
                                            </label>
                                            <input type="password" name='password' placeholder="i.e. xxxxxxxx" className="w-full h-10 rounded bg-gray-100 px-4 py-1 text-sm font-sans mt-1 outline-none focus:shadow" required autoComplete="off" onChange={onChangeForm} />
                                        </div>
                                        <div className="flex flex-col mt-3">
                                            <label className="text-sm text-gray-900 font-serif">Confirm Password
                                                <sup className="text-red-600">*</sup>
                                            </label>
                                            <input type="password" name='cpassword' placeholder="i.e. xxxxxxxx" className="w-full h-10 rounded bg-gray-100 px-4 py-1 text-sm font-sans mt-1 outline-none focus:shadow" required autoComplete="off" onChange={onChangeForm} />
                                        </div>
                                        <button type="submit" className="mt-8 text-xs font-bold text-white rounded bg-blue-600 hover:bg-blue-700 w-full h-10 font-mono flex items-center justify-center" disabled={submit}>
                                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                            {!submit && "LOGIN"}
                                        </button>
                                    </form>
                                }

                                <div className="flex-grow"></div>
                                <p className="text-xs text-gray-400 font-serif text-center mt-12">&copy; 2022 Gigmoz.</p>
                            </div>
                        </div>
                    </div>
                    <div className="h-screen xl:col-span-7 xl:flex items-center">
                        <img className="w-full h-full object-cover" src={loginBg} alt="Login page background" />
                    </div>
                </div>
            </div>
        </>
    )
}
