import React, { useState } from 'react';

import { Menu, Transition } from '@headlessui/react';
import { motion } from "framer-motion";

import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { authAtom, userAtom } from '../../services/config/atoms';

export default function TopBar() {
    const [authStatus, setAuthStatus] = useRecoilState(authAtom);
    const [userData, setUserData] = useRecoilState(userAtom);

    const navigate = useNavigate();

    const logout = () => {
        setAuthStatus(false);
        setUserData({});
        navigate('/');
    }

    return (
        <div className='absolute top-[20px] right-[20px]'>
            <Menu>
                <Menu.Button>
                    <motion.div whileTap={{ scale: 0.90 }}>
                        <div className='w-10 h-10 rounded-full bg-white border-2 border-blue-600 overflow-clip cursor-pointer mr-4 flex items-center justify-center'>
                            {userData.fname[0].toUpperCase()}
                        </div>
                    </motion.div>
                </Menu.Button>
                <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0">
                    <Menu.Items>
                        <div
                            className='bg-white shadow-md rounded-md absolute' style={{ top: '6px', left: '-140px' }}>
                            <div>
                                <ul className='flex flex-col items-start p-1'>
                                    <Menu.Item>
                                        <li className='w-40 cursor-pointer hover:bg-red-100 p-1 rounded-md' onClick={() => { logout() }}>
                                            <div className='flex items-center text-base'>
                                                <i className="las la-sign-out-alt text-lg"></i>
                                                <p className='text-sm font-serif ml-2'>Logout</p>
                                            </div>
                                        </li>
                                    </Menu.Item>
                                </ul>
                            </div>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}
