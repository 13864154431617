import APIServiceLookUp from './serviceAPI';
import { API_URL } from '../models/AppConstent';

export default class ServiceAuth {
    lookupService = new APIServiceLookUp();

    async register(body) {
        var serviceType = "POST";
        var url = `${API_URL}/register`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async login(body) {
        var serviceType = "POST";
        var url = `${API_URL}/login`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async verify(body) {
        var serviceType = "POST";
        var url = `${API_URL}/verify`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async verifyPass(body) {
        var serviceType = "POST";
        var url = `${API_URL}/verifyPass`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async forgot(body) {
        var serviceType = "POST";
        var url = `${API_URL}/forgot`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async reset(body) {
        var serviceType = "POST";
        var url = `${API_URL}/resetPass`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async getUsers(body) {
        var serviceType = "POST";
        var url = `${API_URL}/getUsers`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async updateUserById(body) {
        var serviceType = "POST";
        var url = `${API_URL}/updateUserById`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

}