import React, { useEffect, Suspense, useRef, useState } from 'react';

import * as THREE from 'three';
import { useThree } from '@react-three/fiber';

import { useGLTF } from '@react-three/drei';

export default function Product23({ ...props }) {
    const { nodes, materials } = useGLTF(props.product.config['gltf']);
    const { gl, scene, camera } = useThree();

    const [isLabel, setisLabel] = useState(false);

    const productRef = useRef();

    const loadData = () => {
        console.log(nodes);
        console.log(materials);
        if (props.texture.isAva) {
            const textureLoader = new THREE.TextureLoader();
            textureLoader.load(props.texture.url, (t) => {
                materials.label.map.source = t.source;
                setisLabel(true);
            });
        } else {
            setisLabel(false);
        }
        props.onLoad();
    }

    const TopScreenShot = () => {
        camera.position.set(0, 0.26, 0);
        camera.rotation.set(-Math.PI / 2, 0, 0);
        gl.render(scene, camera);
        gl.toneMapping = THREE.ACESFilmicToneMapping
        gl.toneMappingExposure = 0.6
        gl.outputEncoding = THREE.sRGBEncoding
        gl.preserveDrawingBuffer = true
        gl.domElement.toBlob(
            function (blob) {
                let urlData = [];
                urlData.push(URL.createObjectURL(blob));
                BottomScreenShot(urlData);
            },
            'image/jpg',
            1.0
        );
    }

    const BottomScreenShot = (urlData) => {
        camera.position.set(0, -0.20, 0);
        camera.rotation.set(Math.PI / 2, 0, 0);
        gl.render(scene, camera);
        gl.toneMapping = THREE.ACESFilmicToneMapping
        gl.toneMappingExposure = 0.6
        gl.outputEncoding = THREE.sRGBEncoding
        gl.preserveDrawingBuffer = true
        gl.domElement.toBlob(
            function (blob) {
                urlData.push(URL.createObjectURL(blob));
                FrontScreenShot(urlData);
            },
            'image/jpg',
            1.0
        );
    }

    const FrontScreenShot = (urlData) => {
        camera.position.set(0, 0.02, 0.22);
        camera.rotation.set(0, 0, 0);
        gl.render(scene, camera);
        gl.toneMapping = THREE.ACESFilmicToneMapping
        gl.toneMappingExposure = 0.6
        gl.outputEncoding = THREE.sRGBEncoding
        gl.preserveDrawingBuffer = true
        gl.domElement.toBlob(
            function (blob) {
                urlData.push(URL.createObjectURL(blob));
                SideScreenShot(urlData);
            },
            'image/jpg',
            1.0
        );
    }

    const SideScreenShot = (urlData) => {
        camera.position.set(0.26, 0.02, 0);
        camera.rotation.set(0, Math.PI / 2, 0);
        gl.render(scene, camera);
        gl.toneMapping = THREE.ACESFilmicToneMapping
        gl.toneMappingExposure = 0.6
        gl.outputEncoding = THREE.sRGBEncoding
        gl.preserveDrawingBuffer = true
        gl.domElement.toBlob(
            function (blob) {
                urlData.push(URL.createObjectURL(blob));
                OrthoScreenShot(urlData);
            },
            'image/jpg',
            1.0
        );
    }

    const OrthoScreenShot = (urlData) => {
        camera.position.set(-0.16, 0.16, 0.16);
        camera.rotation.set(-0.78, -0.61, -0.52);
        gl.render(scene, camera);
        gl.toneMapping = THREE.ACESFilmicToneMapping
        gl.toneMappingExposure = 0.6
        gl.outputEncoding = THREE.sRGBEncoding
        gl.preserveDrawingBuffer = true
        gl.domElement.toBlob(
            function (blob) {
                urlData.push(URL.createObjectURL(blob));
                camera.position.set(0, 0.5, 1);
                camera.rotation.set(0, 0, 0);
                props.onExport(urlData);
            },
            'image/jpg',
            1.0
        );
    }

    useEffect(() => {
        if (props.exportRe) {
            TopScreenShot();
        }
        return () => {
        }
    }, [props.exportRe]);

    useEffect(() => {
        loadData();
        return () => {
        }
    }, []);

    return (
        <group {...props} dispose={null}>
            <group ref={productRef}>
                {
                    isLabel && <mesh castShadow geometry={nodes.stick1.geometry} material={materials.label} material-map={materials.label.map}>
                    </mesh>
                }
                {
                    isLabel && <mesh castShadow geometry={nodes.stick2.geometry} material={materials.label} material-map={materials.label.map}>
                    </mesh>
                }
                <group position={[0, 0, 0]} rotation={[0, 0, 0]}>
                    <mesh castShadow geometry={nodes.Body.geometry} material={materials.Body}>
                        {!props.copacity['bodyColor'] && <meshPhongMaterial color={props.formData['bodyColor'] === undefined ? '#2C3E50' : props.formData['bodyColor']} />}
                    </mesh>
                    <mesh castShadow geometry={nodes.Lid.geometry} material={materials.Lid}>
                        {!props.copacity['lidColor'] && <meshPhongMaterial color={props.formData['lidColor'] === undefined ? '#273746' : props.formData['lidColor']} />}
                    </mesh>
                </group>
            </group>
        </group>
    );
}