import React from 'react';

// import { Document, Page, Text, View } from '@react-pdf/renderer';

export default function ComponentPdf() {

    return (
        // <Document>
        //     <Page size="A4">
        //         <View>
        //             <Text>Section #1</Text>
        //         </View>
        //         <View>
        //             <Text>Section #2</Text>
        //         </View>
        //     </Page>
        // </Document>
        <h1>Hellow</h1>
    );
}
