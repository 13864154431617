import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { authAtom } from '../core/services/config/atoms';

import { useAlert } from 'react-alert';
import { useNavigate, useParams } from 'react-router-dom';

import loginBg from '../assets/images/bg-login.jpg';
import ServiceAuth from '../core/services/repository/serviceAuth';

export default function PageAuthVerify() {

    const [authStatus] = useRecoilState(authAtom);

    const { token } = useParams();

    const [isError, setIsError] = useState(false);

    const alert = useAlert();
    const navigate = useNavigate();

    const authService = new ServiceAuth();

    const gotoLogin = () => {
        navigate('/', { replace: true });
    }

    const loadData = () => {
        let body = {
            token: token
        };
        authService.verify(body).then((res) => {
            if (res.status) {
                setIsError(true);
            } else {
                alert.show('Error: Invalid verification token', { type: 'error' });
                setIsError(false);
            }
        });
    }

    useEffect(() => {
        if (authStatus) {
            navigate('/home');
        }
    }, [authStatus, navigate]);

    useEffect(() => {
        loadData();
        return () => {
            console.log("Done");
        }
    }, []);

    return (
        <>
            <div className="w-screen h-screen overflow-hidden relative">
                <div className="xl:grid xl:grid-cols-12 xl:gap-0">
                    <div className="absolute xl:relative w-full xl:col-span-5">
                        <div className="h-screen flex flex-col items-center justify-center xl:bg-blue-50">
                            <div className="shadow-lg rounded-xl px-4 xl:px-10 py-20 xl:py-10 bg-white mt-6 mx-2 max-w-md md:w-auth w-11/12">
                                <h1 className="text-2xl text-black font-serif font-medium">Welcome back!</h1>
                                <h2 className="text-sm text-gray-400 font-serif">We`re so excited to see you again!</h2>
                                <div className={`${isError ? 'bg-green-100' : 'bg-red-100'} p-3 rounded-md mt-8`}>
                                    <p className={`text-sm ${isError ? 'text-green-600' : 'text-red-600'} text-center`}>
                                        {isError && <>Your account has been activated,<br />Please login into your account.</>}
                                        {!isError && <>Invalid verification token<br /> or <br />Token already verifyed please try login</>}
                                    </p>
                                </div>
                                <p className='mt-4 text-sm text-black font-medium text-center'>Back to <span className='text-blue-600 cursor-pointer' onClick={() => gotoLogin()}>Login</span></p>
                                <div className="flex-grow"></div>
                                <p className="text-xs text-gray-400 font-serif text-center mt-12">&copy; 2022 Gigmoz.</p>
                            </div>
                        </div>
                    </div>
                    <div className="h-screen xl:col-span-7 xl:flex items-center">
                        <img className="w-full h-full object-cover" src={loginBg} alt="Login page background" />
                    </div>
                </div>
            </div>
        </>
    );
}
