import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { authAtom } from '../core/services/config/atoms';

import Lottie from 'lottie-react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';

import loginBg from '../assets/images/bg-login.jpg';
import animBtnLoading from '../assets/anim/anim-btnLoading.json';

import ServiceAuth from '../core/services/repository/serviceAuth';

export default function PageAuthForget() {

    const [authStatus, setAuthStatus] = useRecoilState(authAtom);

    const [formData, setFormData] = useState({});

    const [isSent, setIsSent] = useState(false);
    const [submit, setSubmit] = useState(false);

    const alert = useAlert();
    const navigate = useNavigate();

    const authService = new ServiceAuth();

    const onChangeForm = (e) => {
        var { value, name } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmit(true);
        var body = {
            email: formData['email'],
        }
        authService.forgot(body, '').then((d) => {
            if (d.status) {
                setSubmit(false);
                setIsSent(true);
            } else {
                alert.show('Error: ' + d.msg, { type: 'error' });
                setSubmit(false);
            }
        });
    }

    const gotoLogin = () => {
        navigate('/', { replace: true });
    }

    useEffect(() => {
        if (authStatus) {
            navigate('/home');
        }
    }, [authStatus, navigate]);

    return (
        <>
            <div className="w-screen h-screen overflow-hidden relative">
                <div className="xl:grid xl:grid-cols-12 xl:gap-0">
                    <div className="absolute xl:relative w-full xl:col-span-5">
                        <div className="h-screen flex flex-col items-center justify-center xl:bg-blue-50">
                            <div className="shadow-lg rounded-xl px-4 xl:px-10 py-20 xl:py-10 bg-white mt-6 mx-2 max-w-md md:w-auth w-11/12">
                                <h1 className="text-2xl text-black font-serif font-medium">Welcome back!</h1>
                                <h2 className="text-sm text-gray-400 font-serif">We`re so excited to see you again!</h2>

                                {
                                    !isSent && <form className="mt-8" onSubmit={handleSubmit}>
                                        <div className="flex flex-col">
                                            <label className="text-sm text-gray-900 font-serif">Email ID
                                                <sup className="text-red-600">*</sup>
                                            </label>
                                            <input type="email" name='email' placeholder="i.e. jhon.deo@gmail.com" className="w-full h-10 rounded bg-gray-100 px-4 py-1 text-sm font-sans mt-1 outline-none focus:shadow" required autoComplete="off" onChange={onChangeForm} />
                                        </div>
                                        <button type="submit" className="mt-4 text-xs font-bold text-white rounded bg-blue-600 hover:bg-blue-700 w-full h-10 font-mono flex items-center justify-center" disabled={submit}>
                                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" />}
                                            {!submit && "SEND LINK"}
                                        </button>
                                        <p className='mt-8 text-sm text-black font-medium text-center'>Back to <span className='text-blue-600 cursor-pointer' onClick={() => gotoLogin()}>Login</span></p>
                                    </form>
                                }
                                {
                                    isSent && <>
                                        <div className='bg-green-100 p-3 rounded-md mt-8'>
                                            <p className='text-sm text-green-600 text-center'>An email has been sent to your registered email address with password reset link.</p>
                                        </div>
                                        <p className='mt-4 text-sm text-black font-medium text-center'>Back to <span className='text-blue-600 cursor-pointer' onClick={() => gotoLogin()}>Login</span></p>
                                    </>
                                }
                                <div className="flex-grow"></div>
                                <p className="text-xs text-gray-400 font-serif text-center mt-12">&copy; 2022 Gigmoz.</p>
                            </div>
                        </div>
                    </div>
                    <div className="h-screen xl:col-span-7 xl:flex items-center">
                        <img className="w-full h-full object-cover" src={loginBg} alt="Login page background" />
                    </div>
                </div>
            </div>
        </>
    );
}
