import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { modeAtom, authAtom } from './core/services/config/atoms';

import PageAuth from './pages/PageAuth';
import PageAuthVerify from './pages/PageAuthVerify';
import PageAuthForget from './pages/PageAuthForget';
import PageHome from './pages/PageHome';
import Page404 from './pages/Page404';
import PageFabConsole from './pages/PageFabConsole';
import PageAuthReset from './pages/PageAuthReset';
import ComponentPdf from './core/components/misc/ComponentPdf';
import PageAdminAuth from './pages/PageAdminAuth';
import PageAdminHome from './pages/PageAdminHome';

export default function App() {

    const [modeStatus] = useRecoilState(modeAtom);
    const [authStatus] = useRecoilState(authAtom);

    useEffect(() => {
        const bodyEl = document.querySelector("body");
        modeStatus ? bodyEl.classList.add("dark") : bodyEl.classList.remove("dark");
    }, [modeStatus]);

    return (
        <>
            <Router basename='/'>
                <Routes>
                    <Route exact path="/" element={<PageAuth />} />
                    <Route exact path="/admin" element={<PageAdminAuth />} />
                    <Route exact path="/ahome" element={<PageAdminHome />} />
                    <Route exact path="/verify/:token" element={<PageAuthVerify />} />
                    <Route exact path="/reset/:token" element={<PageAuthReset />} />
                    <Route exact path="/forgot" element={<PageAuthForget />} />
                    <Route exact path="/pdf" element={<ComponentPdf />} />
                    <Route exact path="/home" element={authStatus ? <PageHome /> : <Navigate to={"/"} replace={true} />} />
                    <Route exact path="/fabconsole" element={authStatus ? <PageFabConsole /> : <Navigate to={"/"} replace={true} />} />
                    <Route path="*" element={<Page404 />} />
                </Routes>
            </Router>
        </>
    )
}