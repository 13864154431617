import React, { useState, useEffect } from 'react';


import { useRecoilState } from 'recoil';
import { userAtom, productAtom, loadingAtom } from '../core/services/config/atoms';

import Lottie from 'lottie-react';
import { useNavigate } from 'react-router-dom';

import TopBar from '../core/components/ui/TopBar';

import ServiceProducts from '../core/services/repository/serviceProducts';

import animEmpty from '../assets/anim/anim-empty.json';

export default function PageHome() {

    const [userData] = useRecoilState(userAtom);
    const [productData, setProductData] = useRecoilState(productAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);

    const [products, setProducts] = useState([]);
    const [productsFillter, setProductsFillter] = useState([]);

    const navigate = useNavigate();

    const productService = new ServiceProducts();

    const loadData = () => {
        (async function () {
            var res = await productService.getAllProducts({});
            setProducts(res.data);
            setProductsFillter(res.data);
        })();
    }

    const onChangeSearch = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case "search":
                let data = productsFillter.filter((item) => (item.title.includes(value)));
                setProducts(data);
                break;
            default:
                break;
        }
    }

    const onProductClick = (item) => {
        setProductData(item);
        setLoading(true);
        navigate('/fabconsole', { replace: true });
    }

    useEffect(() => {
        loadData();
        return () => {
        }
    }, []);

    return (
        <div className='w-full h-screen overflow-y-auto'>
            <div className="bg-green-100 h-1/3 w-full bgHead flex flex-col items-center justify-center relative" >
                <TopBar />
                <div>
                    <h1 className='text-balck font-serif font-medium text-2xl'>Welcome, {userData.fname}</h1>
                    <p className='text-balck font-sans text-sm'>The web smart 3D experience for products.</p>
                    <div className='w-[480px] h-12 mt-4 px-2 py-1 bg-white text-xs rounded flex items-center form-control'>
                        <span>
                            <i className="las la-search text-xl"></i>
                        </span>
                        <input type="text" name="search" placeholder='Search for products.' className='w-full h-10 px-2 text-xs outline-none' onChange={onChangeSearch} />
                    </div>
                </div>
            </div>
            <div className='bg-white w-full overflow-hidden'>
                {
                    products.length === 0 && <div className='w-full h-full'>
                        <Lottie animationData={animEmpty} className="w-80 mx-auto mb-2" loop={true} />
                        <p className=" intro-y text-gray-900 lg:text-lg text-base text-center font-serif font-normal w-4/5 mx-auto">
                            No products found.<br />
                            Here all the products will be displayed
                        </p>
                    </div>
                }
                {
                    products.length !== 0 && <>
                        <h2 className='pl-4 py-2 text-xl font-medium font-serif'>Products</h2>
                        <div className='w-full grid grid-cols-3 gap-2'>
                            {
                                products.map((product, index) => {
                                    return <div className='col-span-1 px-4 py-2 transition-all hover:scale-105' key={index}>
                                        <div className='bg-white shadow rounded min-h-[240px] p-2 flex flex-col cursor-pointer' onClick={() => { onProductClick(product) }}>
                                            <img src={product.config['thumbnail']} alt='banner' className='flex-grow' />
                                            <h2 className='text-black font-serif text-base mt-2'>{product.title}</h2>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </>
                }
                <hr className='mt-6' />
                <footer className='w-full py-4'>
                    <p className="text-sm text-gray-400 font-serif text-center">&copy; 2022 Gigmoz.</p>
                </footer>
            </div>
        </div>
    );
}
