import { atom, DefaultValue } from 'recoil';

const localStorageEffect = key => ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
    }

    onSet(newValue => {
        if (newValue instanceof DefaultValue) {
            localStorage.removeItem(key);
        } else {
            localStorage.setItem(key, JSON.stringify(newValue));
        }
    });
};

export const modeAtom = atom({
    key: "modeAtom",
    default: false,
    effects_UNSTABLE: [
        localStorageEffect('modeAtom'),
    ]
});

export const authAtom = atom({
    key: "authAtom",
    default: false,
    effects_UNSTABLE: [
        localStorageEffect('authAtom'),
    ]
});

export const userAtom = atom({
    key: "userAtom",
    default: {},
    effects_UNSTABLE: [
        localStorageEffect('userAtom'),
    ]
});

export const productAtom = atom({
    key: "productAtom",
    default: {},
});

export const loadingAtom = atom({
    key: "loadingAtom",
    default: true,
});

export const exporAtom = atom({
    key: "exporAtom",
    default: false,
});