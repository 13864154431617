import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { authAtom, userAtom } from '../core/services/config/atoms';

import Lottie from 'lottie-react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';

import loginBg from '../assets/images/bg-login.jpg';
import animBtnLoading from '../assets/anim/anim-btnLoading.json';

import ServiceAuth from '../core/services/repository/serviceAuth';

export default function PageAuth() {
    const [authStatus, setAuthStatus] = useRecoilState(authAtom);
    const [userData, setUserData] = useRecoilState(userAtom);

    const [formDataA, setFormDataA] = useState({});
    const [formDataB, setFormDataB] = useState({});

    const [isSent, setIsSent] = useState(false);
    const [isRegister, setIsRegister] = useState(false);
    const [submit, setSubmit] = useState(false);

    const alert = useAlert();
    const navigate = useNavigate();

    const authService = new ServiceAuth();

    const onChangeForm = (e) => {
        var { value, name } = e.target;
        if (isRegister) {
            setFormDataA({ ...formDataA, [name]: value });
        } else {
            setFormDataB({ ...formDataB, [name]: value });
        }
    }

    const gotoLogin = () => {
        setIsRegister(false);
        setIsSent(false);
        setSubmit(false);
        setFormDataA({});
    }

    const gotoRegister = () => {
        setIsRegister(true);
        setIsSent(false);
        setSubmit(false);
        setFormDataB({});
    }

    const gotoForget = () => {
        navigate('/forgot', { replace: true });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmit(true);
        if (isRegister) {
            if (formDataA['password'] === formDataA['cpassword']) {
                var body = {
                    fname: formDataA['uname'],
                    cname: formDataA['cname'],
                    email: formDataA['email'],
                    password: formDataA['password'],
                }
                authService.register(body, '').then((d) => {
                    if (d.status) {
                        setSubmit(false);
                        setIsSent(true);
                    } else {
                        alert.show('Error: ' + d.msg, { type: 'error' });
                        setSubmit(false);
                    }
                });
            } else {
                alert.show('Password did not match !', { type: 'error' });
            }
        } else {
            var body = {
                email: formDataB['email'],
                password: formDataB['password'],
            }
            authService.login(body, '').then((d) => {
                if (d.status) {
                    setSubmit(false);
                    setUserData(d.data);
                    setAuthStatus(true);
                    navigate('/home', { replace: true });
                } else {
                    alert.show('Error: ' + d.msg, { type: 'error' });
                    setSubmit(false);
                }
            });
        }
    }

    useEffect(() => {
        if (authStatus) {
            navigate('/home');
        }
    }, [authStatus, navigate]);

    return (
        <>
            <div className="w-screen h-screen overflow-hidden relative">
                <div className="xl:grid xl:grid-cols-12 xl:gap-0">
                    <div className="absolute xl:relative w-full xl:col-span-5">
                        <div className="h-screen flex flex-col items-center justify-center xl:bg-blue-50">
                            <div className="shadow-lg rounded-xl px-4 xl:px-10 py-20 xl:py-10 bg-white mt-6 mx-2 max-w-md md:w-auth w-11/12">
                                <h1 className="text-2xl text-black font-serif font-medium">Welcome back!</h1>
                                <h2 className="text-sm text-gray-400 font-serif">We`re so excited to see you again!</h2>

                                {
                                    !isSent && !isRegister && <form className="mt-8" onSubmit={handleSubmit}>
                                        <div className="flex flex-col">
                                            <label className="text-sm text-gray-900 font-serif">Email ID
                                                <sup className="text-red-600">*</sup>
                                            </label>
                                            <input type="email" name='email' placeholder="i.e. jhon.deo@gmail.com" className="w-full h-10 rounded bg-gray-100 px-4 py-1 text-sm font-sans mt-1 outline-none focus:shadow" required autoComplete="off" onChange={onChangeForm} />
                                        </div>
                                        <div className="flex flex-col mt-3">
                                            <label className="text-sm text-gray-900 font-serif">Password
                                                <sup className="text-red-600">*</sup>
                                            </label>
                                            <input type="password" name='password' placeholder="i.e. xxxxxxxx" className="w-full h-10 rounded bg-gray-100 px-4 py-1 text-sm font-sans mt-1 outline-none focus:shadow" required autoComplete="off" onChange={onChangeForm} />
                                        </div>
                                        <p className='mt-4 text-xs text-blue-600 cursor-pointer font-medium text-right' onClick={() => gotoForget()}>Forgot Password ?</p>
                                        <button type="submit" className="mt-8 text-xs font-bold text-white rounded bg-blue-600 hover:bg-blue-700 w-full h-10 font-mono flex items-center justify-center" disabled={submit}>
                                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                            {!submit && "LOGIN"}
                                        </button>
                                        <p className='mt-4 text-sm text-black font-medium text-center'>Don`t have an accout ? <span className='text-blue-600 cursor-pointer' onClick={() => gotoRegister()}>Register</span></p>
                                    </form>
                                }
                                {
                                    !isSent && isRegister && <form className="mt-8" onSubmit={handleSubmit}>
                                        <div className="flex flex-col">
                                            <label className="text-sm text-gray-900 font-serif">Full name
                                                <sup className="text-red-600">*</sup>
                                            </label>
                                            <input type="text" name='uname' placeholder="i.e. Jhon Deo" className="w-full h-10 rounded bg-gray-100 px-4 py-1 text-xs font-sans mt-1 outline-none focus:shadow" required autoComplete="off" onChange={onChangeForm} />
                                        </div>
                                        <div className="flex flex-col mt-3">
                                            <label className="text-sm text-gray-900 font-serif">Company Name <span className='text-gray-400 text-xs'>(Optional)</span>
                                            </label>
                                            <input type="text" name='cname' placeholder="i.e. Company XYZ" className="w-full h-10 rounded bg-gray-100 px-4 py-1 text-xs font-sans mt-1 outline-none focus:shadow" autoComplete="off" onChange={onChangeForm} />
                                        </div>
                                        <div className="flex flex-col mt-3">
                                            <label className="text-sm text-gray-900 font-serif">Email ID
                                                <sup className="text-red-600">*</sup>
                                            </label>
                                            <input type="email" name='email' placeholder="i.e. jhon.deo@gmail.com" className="w-full h-10 rounded bg-gray-100 px-4 py-1 text-xs font-sans mt-1 outline-none focus:shadow" required autoComplete="off" onChange={onChangeForm} />
                                        </div>

                                        <div className="flex flex-col mt-3">
                                            <label className="text-sm text-gray-900 font-serif">Password
                                                <sup className="text-red-600">*</sup>
                                            </label>
                                            <input type="password" name='password' placeholder="i.e. xxxxxxxx" className="w-full h-10 rounded bg-gray-100 px-4 py-1 text-xs font-sans mt-1 outline-none focus:shadow" required autoComplete="off" onChange={onChangeForm} />
                                        </div>
                                        <div className="flex flex-col mt-3">
                                            <label className="text-sm text-gray-900 font-serif">Confirm Password
                                                <sup className="text-red-600">*</sup>
                                            </label>
                                            <input type="password" name='cpassword' placeholder="i.e. xxxxxxxx" className="w-full h-10 rounded bg-gray-100 px-4 py-1 text-xs font-sans mt-1 outline-none focus:shadow" required autoComplete="off" onChange={onChangeForm} />
                                        </div>
                                        <button type="submit" className="mt-8 text-xs font-bold text-white rounded bg-blue-600 hover:bg-blue-700 w-full h-10 font-mono flex items-center justify-center" disabled={submit}>
                                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                            {!submit && "REGISTER"}
                                        </button>
                                        <p className='mt-4 text-sm text-black font-medium text-center'>Already have an accout ? <span className='text-blue-600 cursor-pointer' onClick={() => gotoLogin()}>Login</span></p>
                                    </form>
                                }
                                {
                                    isSent && <>
                                        <div className='bg-green-100 p-3 rounded-md mt-8'>
                                            <p className='text-sm text-green-600 text-center'>Your account needs to be verified from the admin. For verification contact your sales executive.</p>
                                        </div>
                                        <p className='mt-4 text-sm text-black font-medium text-center'>Back to <span className='text-blue-600 cursor-pointer' onClick={() => gotoLogin()}>Login</span></p>
                                    </>
                                }

                                <div className="flex-grow"></div>
                                <p className="text-xs text-gray-400 font-serif text-center mt-12">&copy; 2022 Gigmoz.</p>
                            </div>
                        </div>
                    </div>
                    <div className="h-screen xl:col-span-7 xl:flex items-center">
                        <img className="w-full h-full object-cover" src={loginBg} alt="Login page background" />
                    </div>
                </div>
            </div>
        </>
    );
}