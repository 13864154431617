import React, { useState, useEffect } from 'react';

export default function ProgressBar({ type, secunds }) {

    const [width, setWidth] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setWidth(100);
        }, 10);
        return () => clearInterval(timer);
    }, []);

    return (
        <div className='w-full bg-gray-300' style={{ height: '2px' }}>
            <div
                style={{ width: `${width}%`, transitionDuration: `${secunds}s` }}
                className={`h-full transition-all ${(() => {
                    switch (type) {
                        case 'info':
                            return "bg-blue-400";
                        case 'success':
                            return "bg-green-400";
                        case 'error':
                            return "bg-red-400";
                        default:
                            return "bg-gray-900";
                    }
                })()}`}>
            </div>
        </div>
    )
}
