import APIServiceLookUp from './serviceAPI';
import { API_URL } from '../models/AppConstent';

export default class ServiceProducts {
    lookupService = new APIServiceLookUp();

    async getAllProducts(body) {
        var serviceType = "POST";
        var url = `${API_URL}/getAllProducts`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

}